.invitationContainer {
  margin: 3.5vh 1vw 0vh 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitationCard {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5);
  padding: 5vw;
  width: 80%;
  height: 100%;
  background-size: 100%;
  background-image: url("../assets/Invitation.jpeg");
}

.invitationText {
  display: flex;
  text-align: center;
  font-size: 2.5em;
  flex-direction: column;
}

.names {
  padding-top: 80px;
  margin-bottom: -20px;
}

.location {
  padding: 100px;
}

.time {
  margin-top: -20px;
  margin-bottom: 70px;
}

.manOfHonor {
  margin-top: -20px;
  margin-bottom: 50px;
}

@media screen and (max-width: 1270px) {
  .location {
    padding: 50px;
  }
  .time {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1120px) {
  .invitationText {
    font-size: 2em;
  }
  .location {
    padding: 7-px;
  }
}

@media screen and (max-width: 700px) {
  .names {
    padding-top: 50px;
  }
  .location {
    padding: 45px;
  }
  .time {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 620px) {
  .invitationText {
    font-size: 1.5em;
  }
  .location {
    padding: 30px;
  }
  .manOfHonor {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 465px) {
  .location {
    padding: 0px;
  }
  .manOfHonor {
    margin-bottom: 0px;
  }
}
