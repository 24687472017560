.faqContainer {
    margin-top: 3vh;
    text-align: center;
}

.faqCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.faqTitle {
    margin-bottom: 20px;
}

.faqCard {
    transform-style: preserve-3d;
    padding: 5px;
    margin: 5px 5px;
    width: 350px;
    height: 200px;
    border: #6c9f88;
    border-width: 5px;
    border-style: double;
}

.faqText {
    font-size: 1.5em;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}