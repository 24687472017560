.mainImageContainer {
    position: relative;
}

.mainImage {
    width: 100%;
}

.mainText h1 {
    font-size: 3em;
}