.remainingNumber {
  font-size: 5em;
}

.timerContainer {
  margin-top: 3vh;
  background-color: #fefcec;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.card {
  flex-direction: column;
  display: flex;
}

.remainingCategory {
  font-size: 4em;
}

@media screen and (max-width: 600px) {
  .remainingCategory {
    font-size: 2em;
  }
  .remainingNumber {
    font-size: 3em;
  }
}
