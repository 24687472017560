@import url("https://use.typekit.net/iar6zip.css");

.App {
  overflow: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  font-family: "pf-mediterra";
  font-weight: 400;
  font-style: normal;
}

.title {
  font-size: 6em;
}

@media screen and (min-width: 768px) {
  .App {
    width: 90%;
  }
}

@media screen and (min-width: 992px) {
  .App {
    width: 70%;
  }
}
